import React from 'react';
import createReactClass from 'create-react-class';
import PropTypes from 'prop-types';
import {IntlProvider} from 'react-intl';
import events from '../../../lib/events.js';

import {defaultCopy, languages, setLocale} from '../../../locale/generateLocales.js!';

const IntlWrapper = createReactClass({
  displayName: 'IntlWrapper',
  listener: null,
  propTypes: {
    children: PropTypes.node,
  },

  childContextTypes: {
    currentLocale: PropTypes.string,
    languages: PropTypes.array,
    setLocale: PropTypes.func,
  },

  getInitialState() {
    return {
      locale: 'en-US',
      copy: defaultCopy,
      allCopy: {'en-US': defaultCopy},
    };
  },

  getChildContext() {
    return {
      currentLocale: this.state.locale,
      languages,
      setLocale,
    };
  },

  componentWillMount() {
    this.listener = events.subscribe('set-locale', ({locale, copy}) => {
      const allCopy = this.state.allCopy;
      let workingCopy = '';

      if (copy && locale) {
        allCopy[locale] = copy;
      } else {
        workingCopy = allCopy[locale] || {};
      }
      this.setState({
        copy: workingCopy,
        locale,
        allCopy,
      });
    });
  },

  componentWillUnmount() {
    if (this.listener && this.listener.remove) {
      this.listener.remove();
      this.listener = null;
    }
  },

  render() {
    return <IntlProvider locale={this.state.locale ? this.state.locale.slice(0, 2) : 'en'} messages={this.state.copy} {...this.props}>
      {this.props.children}
    </IntlProvider>;
  },
});

export default IntlWrapper;
